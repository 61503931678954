import { BuOperatingCompanyEntity } from "domain/entity/BuProfile/BuProfileEntity";
import { BuProfileConstant } from "presentation/constant/BuProfile/BuProfileConstant";
import { INITIAL_OPERATING_COMPANY_COL_DEF } from "presentation/constant/BuProfile/OperatingCompanyColumnDefinition";
import { useBuProfileVM } from "presentation/hook/BuProfile/useBuProfileVM";
import { useBuProfileTracked } from "presentation/store/BuProfle/BuProfileProvider";
import ImageUpload from "presentation/view/components/FileUpload";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { memo, useCallback } from "react";

const CompanyBuProfileEditPanel: React.FC = () => {
    const [buProfileState] = useBuProfileTracked();
    const buProfile = buProfileState.buProfile;
    const buProfileVM = useBuProfileVM();
    const { isShowCompanyEditPanel } = buProfileState;
    const tableId = "operatingCompanyTable";
    const BUPROFILE_CONSTANT = BuProfileConstant.buProfile;

    const onEditBuProfile = useCallback((data: BuOperatingCompanyEntity) => {
        if (isShowCompanyEditPanel) return;

        buProfileVM?.onEditCompanyEditPanel(data);
    }, [buProfileVM, isShowCompanyEditPanel]);

    const onAddOperatingCompany = useCallback(() => {
        if (isShowCompanyEditPanel) return;

        buProfileVM?.onAddCompanyEditPanel();
    }, [buProfileVM, isShowCompanyEditPanel]);

    const onDeleteOperatingCompany = useCallback((row: BuOperatingCompanyEntity) => {
        if (isShowCompanyEditPanel) return;

        buProfileVM?.onDeleteCompanyEditPanel(row);
    }, [buProfileVM, isShowCompanyEditPanel]);

    const handleWallPaperChange = useCallback((e: any) => {
        buProfileVM.handleWallpaperChange(e).then(data => {
            e.files = [];
            e.options.clear();
        })
    }, [buProfileVM]);

    return <div className='column' id='ColoumTwo' style={{ width: "66.67%" }} >
        <div className='column-inner-wrapper' style={buProfileState.isShowCompanyEditPanel ? { width: "100%" } : { width: "60%" }}>
            <div>
                <NbisTable
                    id={tableId}
                    columns={INITIAL_OPERATING_COMPANY_COL_DEF}
                    data={buProfile.buOperatingCompanies ?? []}
                    onAddClick={onAddOperatingCompany}
                    onRowDoubleClick={(e: any) => { onEditBuProfile(e.data) }}
                    showPaginator={false}
                    editable={false}
                    showDeleteButton={true}
                    headerLabel="Operating Company"
                    showHeaderIcons={true}
                    showReloadIcon={false}
                    showUploadIcon={false}
                    showActionColumn={true}
                    onDelete={(e: any) => { onDeleteOperatingCompany(e.data) }}
                    gridHeight="customHeight"
                    customHeight="calc(60vh - 125px)"
                    isRowHighligted={true}
                    selectionMode={false}
                    isRowChange={isShowCompanyEditPanel}
                    showPreferenceMenu={false}
                />
            </div>


            <div className='inner-div' style={{ alignItems: "flex-start" }}>
                <div className='title' style={{ marginTop: "30px" }}>{BUPROFILE_CONSTANT.LOGIN_IMAGES}</div>
                <span className="customLabel">{BUPROFILE_CONSTANT.LOGIN_IMAGE}</span>
                <div className={"customContainer"}>
                    <img key="wallpaperImage" src={buProfile.loginPageWallpaperUrl ?? ""} alt="..." style={{ width: "150px", height: "150px", marginRight: "20px" }} />

                    <ImageUpload handleUpload={handleWallPaperChange} disabled={false} />
                </div>
            </div>
        </div>
    </div>;
}

export default memo(CompanyBuProfileEditPanel);